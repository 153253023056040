import React from "react";
import Team from "../components/Team";
import End from "../components/End";
import Subscribe from "../components/Subscribe.js";
import UpcomingGames from "../components/UpcomingGames.js";

const About = () => {
  return (
    <div className="container space-sides">
      <div className="info">
        <div className="flex">
          <div className="soon space-top">
            <h2 className="flicker">Ethos</h2>
          </div>
          <span className="subtitle uppercase">An Indie Game Studio</span>
        </div>
        <br />
        <br />
        <br />
        <br />
        <span className="lighter para space-sides">
          {/* At Ethos, we tell breathtaking stories via beautiful and thrilling
          worlds, creating mesmerizing experiences. Telling stories through
          games is our love that ignites us to keep doing what we do. */}
          Sneha and Anmol, partners in making games (and life as well), are
          passionate storytellers choosing games as the perfect medium to
          express their creative blips.
        </span>
        <span className="lighter small uppercase space-top ">
          Wanna support us?
          <br />
          <span className="buy-us-a-pixel">
            <a
              href="https://www.buymeacoffee.com/ethosGames"
              target="_blank"
              className="no-decor highlight underline"
            >
              buy us a coffee
            </a>
            {/* <span className="highlight">?</span> */}
          </span>
          <br />
          {/* <a href="https://www.buymeacoffee.com/ethosGames" target="_blank">
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              style={{ height: "20px !important", width: "80px !important" }}
            ></img>
          </a> */}
        </span>
      </div>
      <End />
      {/* <UpcomingGames /> */}
      <Team />
      <Subscribe />
    </div>
  );
};

export default About;
