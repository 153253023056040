import React from "react";
import { FaHeart } from "react-icons/fa";

const LogoGif = () => {
  return (
    <div className="space-top center">
      <span>
        Made with <FaHeart color="red" size={20} />
      </span>
      <br />
      <br />
      <span>
        <img src="/games/gifs/Logo/Logo.gif" alt="" className="ss-img-logo" />
      </span>
    </div>
  );
};

export default LogoGif;
