import React from "react";
import logo from "../assets/logo.png";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const { pathname } = useLocation();
  let parts = pathname.split("/");
  const page = parts[1];

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="navbar-brand">
        <img src={logo} alt="" style={{ width: "65px" }} />
      </div>
      {/* <span className="logo-title flicker">ETHOS</span> */}
      <div className="grid">
        <NavLink to="/" className="menu no-decor ts">
          {page === "" ? (
            <span className="highlight">Home</span>
          ) : (
            <span>Home</span>
          )}
        </NavLink>
        {/* <a
          href="https://kombatx.ethostudios.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="menu ts-yel">Kombat-X</span>
        </a> */}
        <NavLink to="/Malhaar" className="menu no-decor ts">
          {page === "Malhaar" ? (
            <span className="highlight">Malhaar</span>
          ) : (
            <span>Malhaar</span>
          )}
        </NavLink>
        <NavLink to="/games" className="menu no-decor ts">
          {page === "games" ? (
            <span className="highlight">Games</span>
          ) : (
            <span>Games</span>
          )}
        </NavLink>
        <NavLink to="/contact" className="menu no-decor ts">
          {page === "contact" ? (
            <span className="highlight">Contact</span>
          ) : (
            <span>Contact</span>
          )}
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
